import { ReactComponent as InfoIco } from "assets/icons/info-ico.svg";
import React, { Fragment, ReactNode } from "react";
import Tooltip from "../Tooltip/Tooltip";
import { useAuthorization } from "../../../common/hooks/useAuthorization";
import Button from "../Button/Button";
import NotificationBadge from "../../features/applicant/components/NotificationBadge/NotificationBadge";

export type functionOptionType = {
  Icon?:
    | React.FunctionComponent<
        React.SVGProps<SVGSVGElement> & {
          title?: string | undefined;
        }
      >
    | string;
  iconClassName?: string;
  label?: string;
  lightText?: string;
  functionOption: (value?: string) => void;
  disabled?: boolean;
  tooltipDisabled?: string;
  countNumber?: number;
  hide?: boolean;
  borderTop?: boolean;
  checked?: boolean;
  childrenIcon?: ReactNode;
  disabledAction?: () => void;
  count?: number | null;
};

type buttonType = {
  text: string;
  type: "Primary" | "Secondary" | "Naked" | "Export";
  IconButton: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  className?: string;
  disabled?: boolean;
  processing?: boolean;
};
type propTypes = {
  functionOption?: functionOptionType[];
  IconDropdown?:
    | React.FunctionComponent<
        React.SVGProps<SVGSVGElement> & {
          title?: string | undefined;
        }
      >
    | string;
  classNameContainer?: string;
  id?: string;
  processing?: boolean;
  menuClassName?: string;
  positionMenu?: string;
  classNameIconContainer?: string;
  fillIcon?: string;
  permissions?: string[];
  noPermissionsText?: string;
  button?: buttonType;
  classNameIcon?: string;
  customToggler?: ReactNode;
  customContent?: ReactNode;
  customContentContainerClassName?: string;
  toggleOnClick?: boolean;
  togglerDisabled?: boolean;
  containerTooltipClassName?: string;
};
const CustomDropdown = (props: propTypes) => {
  const {
    functionOption,
    IconDropdown,
    classNameContainer,
    menuClassName,
    classNameIconContainer,
    id,
    fillIcon,
    permissions,
    noPermissionsText,
    button,
    classNameIcon,
    togglerDisabled,
    customContentContainerClassName,
    customToggler,
    customContent,
    toggleOnClick = true,
    containerTooltipClassName,
  } = props;

  const { allPermissionsCheck } = useAuthorization();
  const selectFunction = (action: functionOptionType) => {
    if (action.disabled) return;
    else
      action.functionOption(props?.id?.toString() ? props.id.toString() : "");
  };

  const handleCheckOption = (action?: any) => {
    if (action.disabled && !action.disabledAction) return;
    if (action?.disabledAction && action?.disabled) {
      action?.disabledAction();
    }
    selectFunction(action);
  };

  return permissions && !allPermissionsCheck(permissions) ? (
    <Tooltip
      id={id}
      toggler={
        button ? (
          <Button
            disabled={true}
            state={button?.processing ? "Loading" : ""}
            type={button?.type || "Primary"}
            FunctionIcon={button.IconButton}
            text={button.text}
            className={button?.className}
          />
        ) : IconDropdown ? (
          <IconDropdown
            className={`[&_path]:fill-[#BFBFBF] ${classNameIcon ? classNameIcon : ""}`}
          />
        ) : null
      }
      content={
        noPermissionsText ? noPermissionsText : "You do not have permission"
      }
      disabled={togglerDisabled}
      className={"mt-3"}
    />
  ) : (
    <Tooltip
      id={id}
      containerClassName={containerTooltipClassName || ""}
      toggler={
        customToggler ? (
          customToggler
        ) : button ? (
          <Button
            disabled={button?.disabled}
            state={button?.processing ? "Loading" : ""}
            type={button?.type || "Primary"}
            FunctionIcon={button.IconButton}
            text={button.text}
            className={button?.className}
          />
        ) : IconDropdown ? (
          <div
            style={{
              cursor: props.processing ? "default" : "pointer",
            }}
            className={`w-[32px] h-[32px] flex justify-center items-center rounded-lg cursor-pointer hover:bg-z-grey-100 group ${
              classNameIconContainer ? classNameIconContainer : ""
            } `}
            onClick={() => {
              // helper function
              !props.processing && document.body.click();
            }}
          >
            <IconDropdown
              className={`${
                classNameContainer ? classNameContainer : ""
              } ${props.processing ? "[&_path]:fill-[#BFBFBF]" : fillIcon ? fillIcon : "group-hover:[&_path]:fill-main-color"} [&_path]:fill-z-grey-500`}
            />
          </div>
        ) : null
      }
      content={
        customContent || functionOption?.length ? (
          <div
            className={
              customContentContainerClassName
                ? customContentContainerClassName
                : "w-max"
            }
          >
            {customContent
              ? customContent
              : functionOption
                ? functionOption
                    .filter((action) => !action.hide)
                    .map((action: functionOptionType, index: number) => (
                      <Fragment key={index}>
                        {action.borderTop ? (
                          <hr className="mt-3 mb-1 border-1 border-z-grey-200" />
                        ) : null}
                        <div
                          onClick={() => handleCheckOption(action)}
                          className={`mt-1.5 first:mt-0 flex justify-between px-[5px] py-[2px] rounded-[5px] text-white items-center mx-2 min-h-[32px]
                               gap-2 cursor-pointer ${
                                 action.checked
                                   ? "bg-[#f4f4f438]"
                                   : action.disabled
                                     ? `bg-dark-gray ${action.disabledAction ? "!cursor-pointer" : "select-none !cursor-default"}`
                                     : "hover:bg-[#f4f4f438]"
                               }`}
                        >
                          <div
                            className={
                              "flex flex-col gap-[2px] items-start w-full"
                            }
                          >
                            <div
                              className={`flex items-center gap-2 text-sm w-full justify-between`}
                            >
                              <div className={"flex items-center gap-1"}>
                                {action?.childrenIcon ? (
                                  action.childrenIcon
                                ) : action.Icon ? (
                                  <action.Icon
                                    className={`w-[25px] ${
                                      action.disabled
                                        ? "[&_path]:fill-z-grey-450"
                                        : ""
                                    } ${action.iconClassName || ""}`}
                                  />
                                ) : (
                                  ""
                                )}
                                {action.label}
                              </div>
                              {action?.count ? (
                                <NotificationBadge
                                  number={action?.count || 0}
                                  type="number"
                                />
                              ) : null}
                            </div>
                            {action?.lightText ? (
                              <span className={"text-sm text-z-grey-400"}>
                                {action?.lightText}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                          {action.disabled || action.countNumber ? (
                            <div
                              className={
                                "flex justify-center items-center gap-2"
                              }
                            >
                              {action.countNumber ? (
                                <>({action.countNumber})</>
                              ) : null}
                              {action.disabled && action.tooltipDisabled ? (
                                <Tooltip
                                  toggler={
                                    <InfoIco className="[&_path]:fill-white" />
                                  }
                                  content={
                                    <p className="font-light text-white text-sm show-animation">
                                      {action.tooltipDisabled}
                                    </p>
                                  }
                                ></Tooltip>
                              ) : (
                                ""
                              )}
                            </div>
                          ) : null}
                        </div>
                      </Fragment>
                    ))
                : null}
          </div>
        ) : null
      }
      className={`${menuClassName ? menuClassName : "!mt-2 !py-2 my-0 px-0"} !px-0`}
      toggleOnClick={toggleOnClick}
      disabled={togglerDisabled}
    />
  );
};
export default CustomDropdown;
