// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";
import {
  getInbox,
  getInboxCount,
  getThread,
  sendNewMessage,
  starThread,
  syncEmails,
} from "./inbox.actions";
import { errorType } from "common/types/reducer.types";
import { threadCountType, threadsType, threadType } from "../inbox.types";

interface reducerType {
  inboxThreads: {
    value: threadsType | null;
    loading: boolean;
    error: errorType | any;
  };
  inboxThread: {
    value: threadType | null;
    loading: boolean;
    processing: boolean;
    error: errorType | any;
  };
  newMessage: {
    processing: boolean;
  };
}

const initialState: reducerType = {
  inboxThreads: {
    value: null,
    loading: false,
    error: null,
  },
  inboxThread: {
    value: null,
    loading: false,
    processing: false,
    error: null,
  },
  newMessage: {
    processing: false,
  },
};

export const inbox = createSlice({
  name: "inbox",
  initialState,
  reducers: {
    clearInbox: (state) => {
      state.inboxThreads = initialState.inboxThreads;
    },
    clearThread: (state) => {
      state.inboxThread = initialState.inboxThread;
    },
    updateThreadReadStatus: (state: any, { payload }) => {
      state.inboxThreads.value = {
        ...state?.inboxThreads?.value,
        data: (state?.inboxThreads?.value?.data || []).map(
          (thread: { _id: string }) => {
            return payload?.id === thread._id
              ? { ...thread, read: payload?.read }
              : thread;
          },
        ),
      };
    },
    updateInboxCountThreads: (state, { payload }) => {
      state.inboxThreads.value = state.inboxThreads.value
        ? {
            ...state.inboxThreads.value,
            count: payload,
          }
        : null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInbox.pending, (state) => {
        state.inboxThreads.loading = true;
      })
      .addCase(getInbox.fulfilled, (state, { payload }) => {
        state.inboxThreads.value = payload;
        state.inboxThreads.loading = false;
      })
      .addCase(getInbox.rejected, (state, { payload }: any) => {
        state.inboxThreads.error =
          payload?.code === "ERR_CANCELED" ? null : payload;
        state.inboxThreads.loading = payload?.code === "ERR_CANCELED";
      })
      .addCase(syncEmails.pending, (state) => {
        state.inboxThreads.loading = true;
      })
      .addCase(syncEmails.fulfilled, (state, { payload }) => {
        state.inboxThreads.value = payload;
        state.inboxThreads.loading = false;
      })
      .addCase(syncEmails.rejected, (state, { payload }: any) => {
        state.inboxThreads.loading = false;
        state.inboxThreads.error = payload;
      })
      .addCase(getThread.pending, (state) => {
        state.inboxThread.loading = true;
      })
      .addCase(getThread.fulfilled, (state, { payload }) => {
        state.inboxThread.value = payload;
        state.inboxThread.loading = false;
      })
      .addCase(getThread.rejected, (state, { payload }: any) => {
        state.inboxThread.error =
          payload?.code === "ERR_CANCELED" ? null : payload;
        state.inboxThread.loading = payload?.code === "ERR_CANCELED";
      })
      .addCase(starThread.pending, (state) => {
        state.inboxThread.processing = true;
      })
      .addCase(starThread.fulfilled, (state, { payload }) => {
        state.inboxThread.value = state.inboxThread.value?._id
          ? {
              ...state.inboxThread.value,
              stars: payload,
            }
          : null;
        state.inboxThread.processing = false;
      })
      .addCase(starThread.rejected, (state, { payload }: any) => {
        state.inboxThread.error =
          payload?.code === "ERR_CANCELED" ? null : payload;
        state.inboxThread.processing = payload?.code === "ERR_CANCELED";
      })
      .addCase(sendNewMessage.pending, (state) => {
        state.newMessage.processing = true;
      })
      .addCase(sendNewMessage.fulfilled, (state) => {
        state.newMessage.processing = false;
      })
      .addCase(sendNewMessage.rejected, (state) => {
        state.newMessage.processing = false;
      });
  },
});

export const {
  clearThread,
  clearInbox,
  updateThreadReadStatus,
  updateInboxCountThreads,
} = inbox.actions;

export default inbox.reducer;
