import { createSlice } from "@reduxjs/toolkit";
import {
  bulkFollowUnfollowApplications,
  getApplications,
} from "./applications.actions";
import {
  applicationsListReducerType,
  bulkFollowUnfollowApplicationType,
} from "../applications.types";

interface ReducerType {
  applicationsList: applicationsListReducerType;
  bulkFollowUnfollowApplication: bulkFollowUnfollowApplicationType;
}

const initialState: ReducerType = {
  applicationsList: {
    values: null,
    loading: true,
    error: null,
  },
  bulkFollowUnfollowApplication: {
    loading: false,
    error: null,
  },
};

export const applications = createSlice({
  name: "applications",
  initialState,
  reducers: {
    updateApplicationFollower: (state, { payload }) => {
      // @ts-ignore
      state.applicationsList.values = {
        ...state.applicationsList.values,
        data: payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getApplications.pending, (state) => {
        state.applicationsList.loading = true;
      })
      .addCase(getApplications.fulfilled, (state, { payload }) => {
        state.applicationsList.values = payload;
        state.applicationsList.loading = false;
      })
      .addCase(getApplications.rejected, (state, { payload }: any) => {
        state.applicationsList.error =
          payload?.code === "ERR_CANCELED" ? null : payload;
        state.applicationsList.loading = payload?.code === "ERR_CANCELED";
      })
      .addCase(bulkFollowUnfollowApplications.pending, (state) => {
        state.bulkFollowUnfollowApplication.loading = true;
      })
      .addCase(
        bulkFollowUnfollowApplications.fulfilled,
        (state, { payload }) => {
          state.bulkFollowUnfollowApplication.loading = false;
        },
      )
      .addCase(
        bulkFollowUnfollowApplications.rejected,
        (state, { payload }) => {
          state.bulkFollowUnfollowApplication.error = payload;
          state.bulkFollowUnfollowApplication.loading = false;
        },
      );
  },
});

export const { updateApplicationFollower } = applications.actions;

export default applications.reducer;
