import { lazy, type FC } from "react";
import { lazyRetry } from "common/utility/Utils";
import { type routeType } from "router/routes/types";

const ApplicationsWrapper: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () => await import("../wrappers/ApplicationsWrapper"),
    ),
);

const ApplicationsTable: FC | any = lazy(
  async () =>
    await lazyRetry(async () => await import("../pages/ApplicationsTable")),
);
const applicationsRoutes: routeType[] = [
  {
    path: "/applications",
    element: <ApplicationsWrapper />,
    permissions: "GET_APPLICATIONS",
    meta: {
      layout: "app",
      title: "Applications",
      type: "page",
      private: true,
      company: true,
    },
    children: [
      {
        path: "",
        element: <ApplicationsTable />,
        meta: {
          title: "Applications",
          type: "component",
          private: true,
          company: true,
        },
      },
    ],
  },
];

export default applicationsRoutes;
