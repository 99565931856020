import { type ReactNode } from "react";
import { createPortal } from "react-dom";

const CreateTooltipPortal = ({ children }: { children: ReactNode }) => {
  const tooltipRoot = document.getElementById("tooltip-root") as HTMLElement;

  return createPortal(children, tooltipRoot);
};

export default CreateTooltipPortal;
