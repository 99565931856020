import {
  microdimensionOptionType,
  microdimensionType,
} from "common/types/data.types";

export function isSpeechProcessingMicrodim(
  m: microdimensionOptionType | microdimensionType,
  isSpeechProcessingEnabled: any
) {
  if (isSpeechProcessingEnabled && m?.willBeScoredByAi && m?.required) {
    return true;
  }
  return false;
}

export function formatMicrodimensionOption(
  m: microdimensionType,
  isDisabled?: boolean
) {
  return {
    _id: m._id,
    value: m._id,
    label: m.name,
    name: m.name,
    description: m.description || "",
    required: m.required,
    protected: m.protected,
    willBeScoredByAi: m.willBeScoredByAi,
    customSkillsContext: m.customSkillsContext,
    isDisabled: isDisabled || false,
  } as microdimensionOptionType;
}
