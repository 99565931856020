// ** Redux Imports
// import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

import { createAsyncThunk } from "@reduxjs/toolkit";

// ** Axios Imports
import { http } from "common/api/http";
import { setToast } from "redux/alert";
import {
  fetchTestSingle,
  fetchTestSingleContents,
} from "./testSingle.services";
import { createTestQuestionType, testQuestionType } from "../testSingle.types";
import { RootState } from "redux/store";
import { getTestContentsType } from "common/helpers/functions.helpers";
import { errorRequestType } from "../../../../common/types/data.types";

export const getTestOverview = createAsyncThunk<
  any,
  { id: string },
  {
    rejectValue: errorRequestType;
    state?: RootState;
  }
>("testSingle/getTestOverview", async ({ id }, { rejectWithValue }) => {
  try {
    const response = await fetchTestSingle(id);
    return response.data;
  } catch (error: any) {
    return rejectWithValue({
      ...error.data,
      code: error.code,
      method: error.config.method,
      type: "CUSTOM",
      style: "MAIN_CONTENT",
    });
  }
});

export const refreshTestOverview = createAsyncThunk(
  "testSingle/refreshTestOverview",
  async ({ id }: { id: string }, { rejectWithValue }) => {
    try {
      const response = await fetchTestSingle(id);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "CUSTOM",
        style: "MAIN_CONTENT",
      });
    }
  },
);

export const updateTestOverview = createAsyncThunk(
  "testSingle/updateTestOverview",
  async (
    {
      id,
      name,
      shortDescription,
      icon,
      hrDescription,
      candidateInstructions,
      timerType,
      timeLimit,
      timeLimitDemo,
      wpm,
      connectToAi,
      statementScaleTestConfig,
      preinterviewAudioOnly,
      hideTestResults,
      percentiles,
    }: {
      id: string;
      name?: string;
      shortDescription?: string;
      icon?: string;
      hrDescription?: string;
      candidateInstructions?: string;
      timerType?: string;
      timeLimit?: number;
      timeLimitDemo?: number;
      wpm?: number;
      connectToAi?: boolean;
      preinterviewAudioOnly?: boolean;
      statementScaleTestConfig?: {
        options: { id?: string; text: string }[];
      };
      hideTestResults?: boolean;
      percentiles?: {
        low: number;
        medium: number;
        top: number;
      };
    },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const response = await http.patch(`/tests/${id}`, {
        name,
        shortDescription,
        icon,
        hrDescription,
        candidateInstructions,
        timerType,
        timeLimit,
        timeLimitDemo,
        wpm,
        connectToAi,
        statementScaleTestConfig,
        preinterviewAudioOnly,
        hideTestResults,
        percentiles,
      });
      dispatch(
        setToast({
          message: "Successfully updated the test.",
          type: "success",
        }),
      );
      const testSingle = await fetchTestSingle(id);
      if (connectToAi !== undefined && typeof connectToAi === "boolean") {
        dispatch(getTestContents({ id }));
      }
      return testSingle.data;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "ACTION",
        style: "TOAST",
      });
    }
  },
);

export const updateTestPercentiles = createAsyncThunk(
  "testSingle/updateTestPercentiles",
  async (
    {
      id,
      percentiles,
    }: {
      id: string;
      percentiles?: {
        low: number;
        medium: number;
        top: number;
      };
    },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const response = await http.patch(`/tests/${id}/percentiles`, {
        percentiles,
      });
      dispatch(
        setToast({
          message: "Successfully updated the test percentiles.",
          type: "success",
        }),
      );
      const testSingle = await fetchTestSingle(id);
      return testSingle.data;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "ACTION",
        style: "TOAST",
      });
    }
  },
);

export const getTestContents = createAsyncThunk(
  "testSingle/getTestContents",
  async ({ id }: { id: string }, { rejectWithValue }) => {
    try {
      const response = await fetchTestSingleContents(id);
      return response.data?.questions ? response.data.questions : null;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "CUSTOM",
        style: "MAIN_CONTENT",
      });
    }
  },
);

export const createTestQuestion = createAsyncThunk<
  any,
  createTestQuestionType,
  { state: RootState }
>(
  "testSingle/createTestQuestion",
  async (
    {
      testId,
      question,
      importance,
      timeLimit,
      wpm,
      isInDemo,
      microDimIds,
      willBeScoredByAiMicroDimIds,
      closedConfig,
      openConfig,
      preinterviewConfig,
      statementScaleTestConfig,
      tts,
      isTextEditor,
      customSkillsContext,
    },
    { dispatch, getState, rejectWithValue },
  ) => {
    try {
      const formattedClosedConfig: any = {
        ...closedConfig,
        options: closedConfig?.options.map((option) => ({
          ...option,
          text: closedConfig?.type === "text" ? option.text : "",
          imageUrl: closedConfig?.type === "text" ? "" : option.imageUrl,
        })),
      };

      const response: { data: { questions: testQuestionType[] } } =
        await http.post(`/tests/${testId}/questions`, {
          question,
          importance,
          timeLimit,
          isInDemo,
          wpm,
          microDimIds,
          willBeScoredByAiMicroDimIds,
          ...(formattedClosedConfig.options && {
            closedConfig: formattedClosedConfig,
          }),
          preinterviewConfig,
          openConfig,
          statementScaleTestConfig,
          tts,
          isTextEditor,
          customSkillsContext,
        });

      const template =
        getState().testSingle.testSingleOverview.value?.template?.enum;

      dispatch(
        setToast({
          message: `Successfully created a ${getTestContentsType(
            template ? template : "",
          )}.`,
          type: "success",
        }),
      );
      const state = getState();
      const questions = state?.testSingle?.testSingleQuestions?.value
        ? state.testSingle.testSingleQuestions.value
        : [];

      const demoQuestions = questions.filter((test) => test.isInDemo);
      const nonDemoQuestions = questions.filter((test) => !test.isInDemo);

      const index = isInDemo ? demoQuestions.length : nonDemoQuestions.length;

      testId && dispatch(getTestContents({ id: testId }));
      testId && dispatch(refreshTestOverview({ id: testId }));

      const newQuestion = response.data || null;

      return { value: newQuestion, index: index };
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "ACTION",
        style: "MODAL",
      });
    }
  },
);

export const updateTestQuestion = createAsyncThunk<
  any,
  createTestQuestionType,
  { state: RootState }
>(
  "testSingle/updateTestQuestion",
  async (
    {
      _id,
      testId,
      question,
      importance,
      timeLimit,
      wpm,
      isInDemo,
      microDimIds,
      willBeScoredByAiMicroDimIds,
      closedConfig,
      preinterviewConfig,
      statementScaleTestConfig,
      openConfig,
      tts,
      isTextEditor,
      customSkillsContext,
    },
    { dispatch, getState, rejectWithValue },
  ) => {
    try {
      const formattedClosedConfig: any = {
        ...closedConfig,
        options: closedConfig?.options.map((option) => ({
          ...option,
          text: closedConfig?.type === "text" ? option.text : "",
          imageUrl: closedConfig?.type === "text" ? "" : option.imageUrl,
        })),
      };

      const response = await http
        .patch(`/tests/${testId}/questions/${_id}`, {
          question,
          importance,
          timeLimit,
          isInDemo,
          wpm,
          microDimIds,
          willBeScoredByAiMicroDimIds,
          ...(formattedClosedConfig.options && {
            closedConfig: formattedClosedConfig,
          }),
          preinterviewConfig,
          statementScaleTestConfig,
          openConfig,
          tts,
          isTextEditor,
          customSkillsContext,
        })
        .then((res) => {
          testId && dispatch(getTestContents({ id: testId }));
          testId && dispatch(refreshTestOverview({ id: testId }));

          const template =
            getState().testSingle.testSingleOverview.value?.template?.enum;

          dispatch(
            setToast({
              message: `Successfully updated the ${getTestContentsType(
                template ? template : "",
              )}.`,
              type: "success",
            }),
          );

          return res;
        });
      return response.data ? response.data : null;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "ACTION",
        style: "MODAL",
      });
    }
  },
);

export const deleteTestQuestion = createAsyncThunk<
  any,
  { testId: string; questionId: string },
  { state: RootState }
>(
  "testSingle/deleteTestQuestion",
  async ({ testId, questionId }, { dispatch, getState, rejectWithValue }) => {
    try {
      const response = await http
        .delete(`/tests/${testId}/questions/${questionId}`)
        .then((res) => {
          testId && dispatch(getTestContents({ id: testId }));
          testId && dispatch(refreshTestOverview({ id: testId }));

          const template =
            getState().testSingle.testSingleOverview.value?.template?.enum;

          dispatch(
            setToast({
              message: `Successfully deleted the ${getTestContentsType(
                template ? template : "",
              )}.`,
              type: "success",
            }),
          );
        });
      return;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "ACTION",
        style: "MODAL",
      });
    }
  },
);

export const publishTest = createAsyncThunk(
  "testSingle/publishTest",
  async (
    {
      id,
      companies,
      state,
    }: { id: string; companies: string[] | null; state: string },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const response =
        state === "update"
          ? await http.put(`/tests/${id}/assign-companies-to-test`, {
              companies: companies,
            })
          : await http.patch(`/tests/${id}/publish`, { companies: companies });

      dispatch(
        setToast({
          message: `The test successfully ${
            state === "update" ? "updated" : "published"
          }.`,
          type: "success",
        }),
      );

      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "ACTION",
        style: "MODAL",
      });
    }
  },
);

export const incubateTest = createAsyncThunk(
  "testSingle/incubateTest",
  async (id: string, { dispatch, rejectWithValue }) => {
    try {
      const response = await http.patch(`/tests/${id}/incubate`);
      dispatch(
        setToast({
          message: "Successfully incubated test.",
          type: "success",
        }),
      );
      dispatch(getTestOverview({ id }));

      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "ACTION",
        style: "MODAL",
      });
    }
  },
);

export const draftTest = createAsyncThunk(
  "testSingle/draftTest",
  async (id: string, { dispatch, rejectWithValue }) => {
    try {
      const response = await http.patch(`/tests/${id}/draft`);
      dispatch(
        setToast({
          message: "Successfully placed test in draft",
          type: "success",
        }),
      );
      dispatch(getTestOverview({ id }));

      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "ACTION",
        style: "MODAL",
      });
    }
  },
);

export const deleteTest = createAsyncThunk(
  "testSingle/deleteTest",
  async (id: string, { dispatch, rejectWithValue }) => {
    try {
      const response = await http.delete(`/tests/${id}`);
      dispatch(
        setToast({
          message: "Successfully deleted test",
          type: "success",
        }),
      );

      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "ACTION",
        style: "MODAL",
      });
    }
  },
);

export const archiveTest = createAsyncThunk(
  "testSingle/archiveTest",
  async (id: string, { dispatch, rejectWithValue }) => {
    try {
      const response = await http.patch(`/tests/${id}/archive`);
      dispatch(
        setToast({
          message: "Successfully archived test",
          type: "success",
        }),
      );

      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "ACTION",
        style: "MODAL",
      });
    }
  },
);

export const getTestApplicants = createAsyncThunk<
  any,
  {
    limit?: number;
    id: string;
    paramsArray?: any;
    signal?: any;
    sort?: string;
  },
  { state: RootState }
>(
  "testSingle/getTestApplicants",
  async ({ id, paramsArray, limit, signal, sort }, { rejectWithValue }) => {
    const params = {
      page: paramsArray?.page || 1,
      limit: paramsArray?.limit || 10,
      ...((sort || paramsArray?.sort) && {
        sort: sort || paramsArray?.sort,
      }),
      ...(paramsArray?.search && {
        search: paramsArray?.search,
      }),
      ...(paramsArray?.filters?.dateFrom && {
        dateFrom: paramsArray?.filters?.dateFrom,
      }),
      ...(paramsArray?.filters?.dateTo && {
        dateTo: paramsArray?.filters?.dateTo,
      }),
    };
    try {
      const response = await http.get(`/application/test/${id}`, {
        params,
        signal,
      });
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "CUSTOM",
        style: "MAIN_CONTENT",
      });
    }
  },
);

export const reorderQuestions = createAsyncThunk(
  "testSingle/reorderQuestions",
  async (
    {
      testId,
      questionsIds,
    }: {
      testId: string;
      questionsIds: string[];
    },
    { rejectWithValue },
  ) => {
    try {
      await http.patch(`/tests/${testId}`, {
        questionsOrder: questionsIds,
      });
      const response2 = await fetchTestSingleContents(testId);
      return response2.data?.questions ? response2.data.questions : null;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "ACTION",
        style: "TOAST",
      });
    }
  },
);
