import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "redux/store";
import { fetchApplications } from "./applications.services";
import { http } from "common/api/http";
import { setToast } from "../../../../redux/alert";
export const getApplications = createAsyncThunk<
  any,
  {
    companyId?: string;
    signal?: any;
    paramsArray?: any;
  },
  { state: RootState }
>(
  "applications/getApplications",
  async ({ companyId, paramsArray, signal }, { rejectWithValue }) => {
    try {
      const params = {
        page: paramsArray?.page || 1,
        limit: paramsArray?.limit || 10,
        ...(paramsArray?.search && {
          search: paramsArray?.search,
        }),
        ...(paramsArray?.sort && {
          sort: paramsArray?.sort,
        }),
        ...(paramsArray?.filters?.positions && {
          positionIds: `${paramsArray?.filters?.positions.map(
            (position: string) => position,
          )}`,
        }),
        ...(paramsArray?.filters?.company && {
          company: paramsArray?.filters.company,
        }),
        ...(companyId && {
          company: companyId,
        }),
        ...(paramsArray?.filters?.state?.length &&
          !paramsArray?.filters?.state?.includes("all") && {
            currentStepState: `${paramsArray?.filters?.state.map(
              (item: string) => item.toUpperCase(),
            )}`,
          }),
        ...(paramsArray?.filters?.dateFrom && {
          dateFrom: paramsArray?.filters?.dateFrom,
        }),
        ...(paramsArray?.filters?.dateTo && {
          dateTo: paramsArray?.filters?.dateTo,
        }),
        ...((paramsArray?.filters?.followerIds === "notFollowed" ||
          paramsArray?.filters?.followerIds?.length) && {
          followerIds:
            paramsArray?.filters?.followerIds === "notFollowed"
              ? "null"
              : `${paramsArray?.filters?.followerIds.map((item: string) => {
                  return item;
                })}`,
        }),
        ...(paramsArray?.filters?.scoreType && {
          [paramsArray?.filters?.scoreType]:
            paramsArray?.filters?.scoreType === "threshold"
              ? Number(paramsArray?.filters?.scoreValue) / 100
              : paramsArray?.filters?.scoreValue,
        }),
        ...(paramsArray?.filters?.test && {
          testIds: paramsArray?.filters.test,
        }),
        ...(paramsArray?.filters?.origin && {
          origin: paramsArray?.filters.origin,
        }),
      };
      const response = await fetchApplications(params, signal);

      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "CUSTOM",
        style: "MAIN_CONTENT",
      });
    }
  },
);

// BULK FOLLOW/UNFOLLOW APPLICATION
export const bulkFollowUnfollowApplications = createAsyncThunk<
  any,
  {
    data: string[];
    action: string;
    paramsArray: any;
    newFollowerId?: string;
  },
  { state: RootState }
>(
  "position/bulkFollowUnfollowApplications",
  async (
    { data, action, paramsArray, newFollowerId },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const response = await http.post(
        `/application/${action === "transfer" ? "unfollow" : action}`,
        {
          applications: data,
          ...(action === "transfer" && {
            newFollowerId: newFollowerId,
          }),
        },
      );
      dispatch(
        setToast({
          message: `Successfully ${action}ed`,
          type: "success",
        }),
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "ACTION",
        style: "TOAST",
      });
    }
  },
);
