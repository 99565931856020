import { ReactNode, useCallback, useEffect, useRef, useState } from "react";
import CreateTooltipPortal from "../CreateTooltipPortal/CreateTooltipPortal";
import { useOutsideClickHandler } from "common/hooks/useOutsideClickHandler";

type ToolTipTypes = {
  toggler: ReactNode;
  content: ReactNode;
  className?: string;
  disabled?: boolean;
  toggleOnClick?: boolean;
  id?: string;
  containerClassName?: string;
};

const Tooltip = ({
  toggler,
  content,
  className,
  disabled,
  toggleOnClick,
  id,
  containerClassName,
}: ToolTipTypes) => {
  const elementRef = useRef<HTMLDivElement | null>(null);
  const [show, setShow] = useState(false);
  const tooltipRef = useRef<HTMLDivElement | null>(null);

  useOutsideClickHandler(elementRef, () => {
    setShow(false);
  });

  const [tooltipPosition, setTooltipPosition] = useState<{
    left: number;
    top: number;
    height: number | string;
    marginLeft: number | string;
  } | null>(null);

  useEffect(() => {
    if (elementRef.current && show) {
      let documentHeight = document.documentElement.clientHeight;
      let tooltipHeight = tooltipRef?.current?.clientHeight || 0;
      let tooltipWidth = tooltipRef?.current?.clientWidth || 0;
      let elementPosition = elementRef.current.getBoundingClientRect();
      let rightEdgeGap = window.innerWidth - elementPosition.left;
      let tooltipPosition = {
        left: elementPosition.left,
        top:
          tooltipHeight + elementPosition.bottom > documentHeight
            ? elementPosition.top - tooltipHeight - 7
            : elementPosition.bottom - 7,
        height:
          tooltipHeight > elementPosition?.top &&
          tooltipHeight + elementPosition.bottom > documentHeight
            ? elementPosition?.top - 10
            : "",
        marginLeft:
          tooltipWidth && rightEdgeGap < tooltipWidth
            ? -tooltipWidth + rightEdgeGap - 8
            : "",
      };

      setTooltipPosition(tooltipPosition);
    }
  }, [show, tooltipRef?.current?.clientHeight]);

  return (
    <>
      <div
        className={`max-w-full select-none ${containerClassName ? containerClassName : ""}`}
        ref={elementRef}
        onMouseEnter={() => !disabled && !toggleOnClick && setShow(true)}
        onMouseLeave={() => !disabled && !toggleOnClick && setShow(false)}
        onClick={(e) => {
          if (toggleOnClick && !disabled) {
            e.stopPropagation();
            show ? setShow(false) : setShow(true);
          }
        }}
      >
        {toggler ? toggler : ""}
        {content && show ? (
          <CreateTooltipPortal>
            <div
              id={id}
              ref={tooltipRef}
              className={`${className || ""} overflow-y-auto my-[5px] max-w-[400px] invisible text-white fixed z-50 bg-black flex flex-col gap-2 p-3 rounded-xl show-animation border border-z-250 mt-2`}
              style={
                tooltipPosition?.left && tooltipPosition?.top
                  ? {
                      top: tooltipPosition.top,
                      left: tooltipPosition.left,
                      height: `${tooltipPosition.height}px`,
                      marginLeft: tooltipPosition.marginLeft
                        ? tooltipPosition.marginLeft
                        : "",
                      visibility: show ? "visible" : "hidden",
                    }
                  : {}
              }
              onClick={(e) => {
                toggleOnClick && show && setShow(false);
                e.stopPropagation();
              }}
            >
              {content}
            </div>
          </CreateTooltipPortal>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default Tooltip;
