// ** Redux Imports
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface FiltersState {
    [key: string]: {
        widths: [];
    };
}

const initialState: FiltersState = {};

export const globalTableWidthsSlice = createSlice({
    name: "globalTableWidthsFilters",
    initialState,

    reducers: {
        setGlobalTableWidths: (state, action) => {
            const { location,widths } = action.payload;

            if (!state[location]) {
                state[location] = {
                    widths: [],
                };
            }

            if (widths !== undefined) {
                state[location].widths = widths;
            }
        },
    },
    extraReducers: (builder) => {},
});
export const { setGlobalTableWidths } =
    globalTableWidthsSlice.actions;
export default globalTableWidthsSlice.reducer;
