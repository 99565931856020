// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";
import {
  createMicrodimension,
  deleteMicrodimension,
  getMicrodimensions,
  updateMicrodimension,
} from "./microdimensions.actions";
import {
  type MicrodimensionsDeleteReducerType,
  type MicrodimensionsFormReducerType,
  type MicrodimensionsLibraryReducerType,
} from "../microdimensions.types";

interface reducerType {
  microdimensionsDelete: MicrodimensionsDeleteReducerType;
  microdimensionsForm: MicrodimensionsFormReducerType;
  microdimensionsList: MicrodimensionsLibraryReducerType;
  viewStyle: "CARD" | "LIST";
}

const initialState: reducerType = {
  microdimensionsDelete: {
    value: null,
    processing: false,
    error: null,
  },
  microdimensionsForm: {
    navigate: false,
    edit: null,
    processing: false,
    error: null,
  },
  microdimensionsList: {
    filters: {
      search: "",
      type: "",
      sort: "",
      direction: "",
    },
    loading: true,
    values: null,
    error: null,
  },
  viewStyle: "LIST",
};

export const microdimensions = createSlice({
  name: "microdimensions",
  initialState,
  reducers: {
    setDeleteModal: (state, { payload }) => {
      state.microdimensionsDelete.value = payload;
    },
    setMicrodimensionEditForm: (state, { payload }) => {
      state.microdimensionsForm.edit = payload;
    },
    clearMicrodimensionsList: (state) => {
      state.microdimensionsList = initialState.microdimensionsList;
    },
    clearMicrodimensionDelete: (state) => {
      state.microdimensionsDelete = initialState.microdimensionsDelete;
    },
    clearMicrodimensionsForm: (state) => {
      state.microdimensionsForm = initialState.microdimensionsForm;
    },
    setViewStyle: (state, { payload }) => {
      state.viewStyle = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMicrodimensions.pending, (state) => {
        state.microdimensionsList.loading = true;
      })
      .addCase(getMicrodimensions.fulfilled, (state, { payload }) => {
        state.microdimensionsList.values =
          state.microdimensionsList?.values && payload?.page && payload.page > 1
            ? {
                ...payload,
                data: !payload?.table
                  ? [...state.microdimensionsList.values.data, ...payload.data]
                  : [...payload.data],
              }
            : payload;
        state.microdimensionsForm.error = null;
        state.microdimensionsList.loading = false;
      })
      .addCase(getMicrodimensions.rejected, (state, action: any) => {
        state.microdimensionsList.error =
          action.payload?.code === "ERR_CANCELED" ? null : action.payload;
        state.microdimensionsList.loading =
          action.payload?.code === "ERR_CANCELED";
      })
      .addCase(createMicrodimension.pending, (state) => {
        state.microdimensionsForm.processing = true;
      })
      .addCase(createMicrodimension.fulfilled, (state) => {
        state.microdimensionsForm.navigate = true;
        state.microdimensionsForm.error = null;
        state.microdimensionsForm.processing = false;
      })
      .addCase(createMicrodimension.rejected, (state, action) => {
        state.microdimensionsForm.error = action.payload;
        state.microdimensionsForm.processing = false;
      })
      .addCase(updateMicrodimension.pending, (state) => {
        state.microdimensionsForm.processing = true;
      })
      .addCase(updateMicrodimension.fulfilled, (state) => {
        state.microdimensionsForm.navigate = true;
        state.microdimensionsForm.edit = null;
        state.microdimensionsForm.error = null;
        state.microdimensionsForm.processing = false;
      })
      .addCase(updateMicrodimension.rejected, (state, action) => {
        state.microdimensionsForm.error = action.payload;
        state.microdimensionsForm.processing = false;
      })
      .addCase(deleteMicrodimension.pending, (state) => {
        state.microdimensionsDelete.processing = true;
      })
      .addCase(deleteMicrodimension.fulfilled, (state, action) => {
        state.microdimensionsDelete.processing = false;
        state.microdimensionsDelete.value = null;
        state.microdimensionsList.values = action.payload;
        state.microdimensionsDelete.error = null;
      })
      .addCase(deleteMicrodimension.rejected, (state, action) => {
        state.microdimensionsDelete.value = null;
        state.microdimensionsDelete.processing = false;
        state.microdimensionsDelete.error = action.payload;
      });
  },
});

export const {
  setDeleteModal,
  setMicrodimensionEditForm,
  clearMicrodimensionsList,
  clearMicrodimensionDelete,
  clearMicrodimensionsForm,
  setViewStyle,
} = microdimensions.actions;

export default microdimensions.reducer;
