// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";
import {
  createPosition,
  createPositionByTemplate,
  createPositionTemplate,
  deletePositionTemplate,
  getPositions,
  getPositionTemplates,
} from "./positions.actions";
import {
  positionByTemplate,
  type positionsFormType,
  type positionsListType,
  positionsTemplatesListType,
} from "../positions.types";
import { errorType } from "../../../../common/types/reducer.types";

interface positionsStateType {
  positionsForm: positionsFormType;
  positionsList: positionsListType;
  positionTemplatesList: positionsTemplatesListType;
  positionByTemplate: positionByTemplate;
  positionTemplate: {
    loading: boolean;
    error: errorType | any;
  };
}

const initialState: positionsStateType = {
  positionsForm: {
    navigate: null,
    processing: false,
    template: null,
    error: null,
    info: {
      basics: null,
      location: null,
      education: null,
      licence: null,
      experience: null,
      ambition: null,
      industry: null,
      moreInfo: null,
      skills: null,
    },
  },
  positionsList: {
    filters: {
      search: "",
      type: "",
      sort: "",
      template: "",
      microdimensions: [],
    },
    loading: true,
    values: null,
    error: null,
  },
  positionTemplatesList: {
    loading: true,
    values: null,
    error: null,
  },
  positionByTemplate: {
    loading: false,
    error: null,
  },
  positionTemplate: {
    loading: false,
    error: null,
  },
};

export const positions = createSlice({
  name: "positions",
  initialState,
  reducers: {
    clearPositionsList: (state) => {
      state.positionsList = initialState.positionsList;
    },
    clearTestLibraryForm: (state) => {
      state.positionsForm = initialState.positionsForm;
    },
    createPositionForm: (state, action) => {
      state.positionsForm.info = {
        ...state.positionsForm.info,
        [action.payload.name]: action.payload.information,
      };
    },
    setPositionFormData: (state, action) => {
      state.positionsForm.info = action.payload;
    },
    clearSpecificNamePositionForm: (state, action) => {
      state.positionsForm.info = {
        ...state.positionsForm.info,
        [action.payload.name]: null,
      };
    },
    clearPositionWizardForm: (state) => {
      state.positionsForm.info = initialState.positionsForm.info;
    },
    updatePositionTemplateList: (state: any, { payload }) => {
      state.positionTemplatesList = {
        ...state.positionTemplatesList,
        values: {
          ...state.positionTemplatesList?.values,
          data: state.positionTemplatesList?.values?.data?.map((item: any) =>
            item._id === payload._id ? payload : item,
          ),
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPositions.pending, (state) => {
        state.positionsList.loading = true;
      })
      .addCase(getPositions.fulfilled, (state, { payload }) => {
        state.positionsList.values = payload;
        state.positionsList.loading = false;
      })
      .addCase(getPositions.rejected, (state, { payload }: any) => {
        state.positionsList.error =
          payload?.code === "ERR_CANCELED" ? null : payload;
        state.positionsList.loading = payload?.code === "ERR_CANCELED";
      })
      .addCase(createPosition.pending, (state) => {
        state.positionsForm.processing = true;
      })
      .addCase(createPosition.fulfilled, (state, action) => {
        state.positionsForm.template = null;
        state.positionsForm.navigate = action.payload._id;
        state.positionsForm.processing = false;
      })
      .addCase(createPosition.rejected, (state, action) => {
        state.positionsForm.error = action.payload;
        state.positionsForm.processing = false;
      })

      .addCase(getPositionTemplates.pending, (state) => {
        state.positionTemplatesList.loading = true;
      })
      .addCase(getPositionTemplates.fulfilled, (state, { payload }) => {
        state.positionTemplatesList.values = payload;
        state.positionTemplatesList.loading = false;
      })
      .addCase(getPositionTemplates.rejected, (state, { payload }: any) => {
        state.positionTemplatesList.error =
          payload?.code === "ERR_CANCELED" ? null : payload;
        state.positionTemplatesList.loading = payload?.code === "ERR_CANCELED";
      })
      .addCase(createPositionByTemplate.pending, (state) => {
        state.positionByTemplate.loading = true;
      })
      .addCase(createPositionByTemplate.fulfilled, (state, action) => {
        state.positionByTemplate.loading = false;
      })
      .addCase(createPositionByTemplate.rejected, (state, action) => {
        state.positionByTemplate.error = action.payload;
        state.positionByTemplate.loading = false;
      })
      .addCase(deletePositionTemplate.pending, (state) => {
        state.positionByTemplate.loading = true;
      })
      .addCase(deletePositionTemplate.fulfilled, (state, action) => {
        state.positionByTemplate.loading = false;
        // @ts-ignore
        state.positionTemplatesList.values = {
          ...state.positionTemplatesList?.values,
          data: action.payload,
        };
      })
      .addCase(deletePositionTemplate.rejected, (state, action) => {
        state.positionByTemplate.error = action.payload;
        state.positionByTemplate.loading = false;
      })
      .addCase(createPositionTemplate.pending, (state) => {
        state.positionByTemplate.loading = true;
      })
      .addCase(createPositionTemplate.fulfilled, (state, action) => {
        state.positionByTemplate.loading = false;
      })
      .addCase(createPositionTemplate.rejected, (state, action) => {
        state.positionByTemplate.error = action.payload;
        state.positionByTemplate.loading = false;
      });
  },
});

export const {
  clearPositionsList,
  clearTestLibraryForm,
  createPositionForm,
  clearSpecificNamePositionForm,
  clearPositionWizardForm,
  updatePositionTemplateList,
  setPositionFormData,
} = positions.actions;

export default positions.reducer;
