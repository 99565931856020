// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";
import { errorType } from "common/types/reducer.types";
import { getBrowserName } from "common/utility/Utils";
import {
  threadCountType,
  threadsType,
} from "../views/features/inbox/inbox.types";
import { getInboxCount } from "../views/features/inbox/reducer/inbox.actions";

interface alertType {
  isMobile: boolean;
  browser: { value: string; human: string };
  errors: {
    customError: errorType | null;
    filtersError: errorType | null;
    formError: errorType | null;
    actionError: errorType | null;
  };
  inboxCount: {
    companyInboxCount: threadCountType | null;
    personalInboxCount: threadCountType | null;
  };
}

const initialState: alertType = {
  isMobile: false,
  browser: getBrowserName() || { value: "", human: "" },
  inboxCount: {
    companyInboxCount: null,
    personalInboxCount: null,
  },
  errors: {
    customError: null,
    filtersError: null,
    formError: null,
    actionError: null,
  },
};

export const globalSlice = createSlice({
  name: "global",
  initialState,

  reducers: {
    setIsMobile: (state, { payload }) => {
      state.isMobile = payload;
    },

    setGlobalErrors: (state, { payload }: { payload: errorType }) => {
      switch (payload.type) {
        case "ACTION":
          state.errors.actionError = payload;
          break;

        case "FILTERS":
          state.errors.filtersError = payload;
          break;

        case "FORM":
          state.errors.formError = payload;
          break;

        case "CUSTOM":
          state.errors.customError = payload;
          break;

        default:
          state.errors = initialState.errors;
      }
    },
    clearGlobalErrors: (state) => {
      state.errors = initialState.errors;
    },
    updateInboxCount: (state: any, { payload }) => {
      state.inboxCount = {
        ...state.inboxCount,
        [payload?.inboxType]: {
          ...state.inboxCount[payload?.inboxType],
          [payload?.typeValue]:
            state.inboxCount[payload?.inboxType][payload?.typeValue] +
            payload.value,
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getInboxCount.fulfilled, (state, { payload }) => {
      state.inboxCount.companyInboxCount =
        payload?.companyInboxStatuses || null;
      state.inboxCount.personalInboxCount = payload?.userInboxStatuses || null;
    });
  },
});

export const {
  setIsMobile,
  setGlobalErrors,
  clearGlobalErrors,
  updateInboxCount,
} = globalSlice.actions;

export default globalSlice.reducer;
