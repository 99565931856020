import { useState, useEffect } from "react";

declare global {
  interface Window {
    MSStream?: any; // Optional property MSStream
    opera?: any; // Optional property opera
  }
  interface HTMLElement {
    msRequestFullscreen?(): Promise<void>;
    mozRequestFullScreen?(): Promise<void>;
    webkitRequestFullscreen?(): Promise<void>;
  }

  interface Document {
    msExitFullscreen?(): Promise<void>;
    mozCancelFullScreen?(): Promise<void>;
    webkitExitFullscreen?(): Promise<void>;
    msFullscreenElement?: Element | null;
    mozFullScreenElement?: Element | null;
    webkitFullscreenElement?: Element | null;
    fullscreenElement?: Element | null;
  }
}

const useFullScreen = () => {
  const [isFullScreenOpen, setIsFullScreenOpen] = useState(false);

  const enterFullscreen = () => {
    const element = document.documentElement;

    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen(); // IE/Edge
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen(); // Firefox
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen(); // Chrome, Safari
    }

    setIsFullScreenOpen(true);
  };

  const exitFullscreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen(); // IE/Edge
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen(); // Firefox
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen(); // Chrome, Safari
    }

    setIsFullScreenOpen(false);
  };

  const toggleFullScreen = () => {
    if (isFullScreenOpen) {
      exitFullscreen();
    } else {
      enterFullscreen();
    }
  };

  const handleFullscreenChange = () => {
    if (
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement ||
      document.msFullscreenElement
    ) {
      setIsFullScreenOpen(true);
    } else {
      setIsFullScreenOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("fullscreenchange", handleFullscreenChange);
    document.addEventListener("webkitfullscreenchange", handleFullscreenChange);
    document.addEventListener("mozfullscreenchange", handleFullscreenChange);
    document.addEventListener("MSFullscreenChange", handleFullscreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
      document.removeEventListener(
        "webkitfullscreenchange",
        handleFullscreenChange,
      );
      document.removeEventListener(
        "mozfullscreenchange",
        handleFullscreenChange,
      );
      document.removeEventListener(
        "MSFullscreenChange",
        handleFullscreenChange,
      );
    };
  }, []);

  return { isFullScreenOpen, toggleFullScreen };
};

export default useFullScreen;
